<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Customer Groups</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="accent" small to="/business-partners/new-customer-group">
          <v-icon left dark>mdi-plus</v-icon>New Customer Group
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="customerGroups"
              :search="search"
            >
              <template v-slot:item.DiscRel="{ item }">
                <span v-if="item.DiscRel == 'L'">Lowest Discount</span>
                <span v-if="item.DiscRel == 'H'">Highest Discount</span>
                <span v-if="item.DiscRel == 'A'">Average</span>
                <span v-if="item.DiscRel == 'S'">Total</span>
                <span v-if="item.DiscRel == 'M'">Discount Multiples</span>
              </template>
              <!-- effective price -->
              <template v-slot:item.EffecPrice="{ item }">
                <span v-if="item.EffecPrice == 'D'">Default Priority</span>
                <span v-if="item.EffecPrice == 'L'">Lowest Price</span>
                <span v-if="item.EffecPrice == 'H'">Highest Price</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    customerGroups: [],
    headers: [
      { text: "Group Name", value: "GroupName" },
      { text: "Price List", value: "opln.ListName" },
      { text: "Effective Discount", value: "DiscRel" },
      { text: "Effective Price", value: "EffecPrice" },
    ],
  }),
  methods: {
    getGroups() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/bp_groups/C`)
        .then((res) => {
          self.customerGroups = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getGroups();
  },
};
</script>